export const font3 = {
    fontSize: '3vmin'
}

export const font5 = {
    fontSize: '5vmin'
}

export const font4 = {
    fontSize: '4vmin'
}

export const font8 = {
    fontSize: '8vmin'
}

export const font10 = {
    fontSize: '10vmin'
}